body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.shadow-lg {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2) !important;
}

.custom-placeholder::placeholder {
  font-size: 0.75rem; 
  color: #6c757d;
  font-weight:500;
}

.custom-tabs .custom-tab-wrapper {
  position: relative;
  margin-bottom: 10px; 
}

.custom-tabs .custom-tab {
  border: none;
  background: transparent;
  text-align: left;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  color: #333;
  transition: all 0.3s ease;
  position: relative;
}

.custom-tabs .custom-tab:hover {
  color: #007bff;
}

.custom-tabs .custom-tab:hover::before,
.custom-tabs .active-tab::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background: #007bff;
  border-radius: 2px;
}

.custom-tabs .tab-description {
  font-size: 0.875rem;
  color: #666;
  padding-left: 1.5rem;
  margin-top: 5px;
}

.slider-tabs .nav-item {
  flex: 1; 
  text-align: center; 
}

.slider-tabs .nav-item .nav-link {
  background: transparent; 
  color: #000; 
  transition: background 0.3s ease, color 0.3s ease; 
  padding: 1rem;
  border: none; 
  border-radius: 0; 
}

.slider-tabs .nav-item .nav-link.active {
  background: #fdca40; 
  color: #fff; 
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.slider-tabs .nav-item .nav-link:hover {
  background: #fdb94e; 
  color: #fff;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}


.slider-tabs .nav-item {
  display: flex; 
  justify-content: center; 
  align-items: center;
  cursor: pointer;
}

.full-width-tabs .nav-item {
  flex: 1; 
}

.full-width-tabs .nav-link {
  border: 1px solid transparent; 
  text-align: center; 
  padding: 10px 0;
  color: #000; 
  background-color: #f8f9fa; 
  transition: background 0.3s ease; 
}

.full-width-tabs .nav-link:hover {
  background-color: #f1f1f1; 
  color: #000; 
}

.full-width-tabs .nav-link.active {
  background-color: #fdca40; 
  color: #fff; 
  border-color: #fdca40; 
}
.navbar {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.25rem;
}

.nav-link {
  margin-right: 1rem;
  font-size: 1rem;
}

.navbar-toggler {
  border-color: rgba(0, 0, 0, 0.1);
}
.first-section {
  padding-top: 56px; 
}

@media (min-width: 992px) {
  .first-section {
    padding-top: 70px; 
  }
}

